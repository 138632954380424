// Generated by Framer (78001ac)

import { addFonts, addPropertyControls, ControlType, cx, CycleVariantState, getFonts, RichText, useLocaleInfo, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";
import TypeformModalContent from "./RqRBfVdSr";
const TypeformModalContentFonts = getFonts(TypeformModalContent);

const cycleOrder = ["lsgtnXft5"];

const variantClassNames = {lsgtnXft5: "framer-v-17kdut4"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const transitions = {default: {damping: 60, delay: 0, mass: 1, stiffness: 500, type: "spring"}};

const transformTemplate = (_, t) => `perspective(1200px) ${t}`

const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const getProps = ({height, id, title, width, ...props}) => { return {...props, luGEy2HLf: title ?? props.luGEy2HLf ?? "Add Your Store to the Waitlist"} }

const createLayoutDependency = (props, variants) => variants.join('-') + props.layoutDependency

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number;title?: string; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale } = useLocaleInfo()

const {style, className, layoutId, variant, luGEy2HLf, ...restProps} = getProps(props)

const {baseVariant, classNames, gestureVariant, setGestureState, setVariant, transition, variants} = useVariantState({cycleOrder, defaultVariant: "lsgtnXft5", transitions, variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const defaultLayoutId = React.useId()

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<motion.div initial={variant} animate={variants} onHoverStart={() => setGestureState({isHovered: true})} onHoverEnd={() => setGestureState({isHovered: false})} onTapStart={() => setGestureState({isPressed: true})} onTap={() => setGestureState({isPressed: false})} onTapCancel={() => setGestureState({isPressed: false})} className={cx("framer-7b3Qd", classNames)} style={{display: "contents"}}>
<Transition value={transition}><motion.div {...restProps} className={cx("framer-17kdut4", className)} data-framer-name={"Variant 1"} layoutDependency={layoutDependency} layoutId={"lsgtnXft5"} ref={ref} style={{...style}}><RichText __fromCanvasComponent children={<React.Fragment><motion.p style={{"--font-selector": "Q1VTVE9NO09idmlvdXNseSBOYXJyb3cgQm9sZA==", "--framer-font-family": "\"Obviously Narrow Bold\", sans-serif", "--framer-font-size": "13px", "--framer-line-height": "136%", "--framer-text-alignment": "center", "--framer-text-transform": "uppercase"}}>Enroll Your Store</motion.p></React.Fragment>} className={"framer-4gt97f"} fonts={["CUSTOM;Obviously Narrow Bold"]} layoutDependency={layoutDependency} layoutId={"uEl_LpZBT"} style={{"--framer-link-text-color": "rgb(0, 153, 255)", "--framer-link-text-decoration": "underline", "--framer-paragraph-spacing": "0px"}} text={luGEy2HLf} transformTemplate={transformTemplate} verticalAlignment={"top"} withExternalLayout/><motion.div className={"framer-1qdxd8y-container"} layoutDependency={layoutDependency} layoutId={"QC7Q32Sia-container"} transformTemplate={transformTemplate}><TypeformModalContent height={"100%"} id={"QC7Q32Sia"} layoutId={"QC7Q32Sia"} style={{height: "100%", width: "100%"}} width={"100%"}/></motion.div></motion.div></Transition>
</motion.div>
</LayoutGroup>)

});

const css = [".framer-7b3Qd [data-border=\"true\"]::after { content: \"\"; border-width: var(--border-top-width, 0) var(--border-right-width, 0) var(--border-bottom-width, 0) var(--border-left-width, 0); border-color: var(--border-color, none); border-style: var(--border-style, none); width: 100%; height: 100%; position: absolute; box-sizing: border-box; left: 0; top: 0; border-radius: inherit; pointer-events: none; }", "@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-7b3Qd .framer-ivqdgy { display: block; }", ".framer-7b3Qd .framer-17kdut4 { align-content: center; align-items: center; display: flex; flex-direction: column; flex-wrap: nowrap; gap: 23px; height: 586px; justify-content: center; overflow: hidden; padding: 0px 0px 0px 0px; position: relative; width: 840px; }", ".framer-7b3Qd .framer-4gt97f { flex: none; height: auto; position: relative; white-space: pre; width: auto; }", ".framer-7b3Qd .framer-1qdxd8y-container { flex: none; height: 80%; position: relative; width: 100%; }", "@supports (background: -webkit-named-image(i)) and (not (font-palette:dark)) { .framer-7b3Qd .framer-17kdut4 { gap: 0px; } .framer-7b3Qd .framer-17kdut4 > * { margin: 0px; margin-bottom: calc(23px / 2); margin-top: calc(23px / 2); } .framer-7b3Qd .framer-17kdut4 > :first-child { margin-top: 0px; } .framer-7b3Qd .framer-17kdut4 > :last-child { margin-bottom: 0px; } }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 586
 * @framerIntrinsicWidth 840
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["fixed","fixed"]}}}
 * @framerVariables {"luGEy2HLf":"title"}
 */
const FramerFN86_ZFDn: React.ComponentType<Props> = withCSS(Component, css, "framer-7b3Qd") as typeof Component;
export default FramerFN86_ZFDn;

FramerFN86_ZFDn.displayName = "Typeform Modal Component";

FramerFN86_ZFDn.defaultProps = {height: 586, width: 840};

addPropertyControls(FramerFN86_ZFDn, {luGEy2HLf: {defaultValue: "Add Your Store to the Waitlist", displayTextArea: false, title: "Title", type: ControlType.String}} as any)

addFonts(FramerFN86_ZFDn, [{family: "Obviously Narrow Bold", moduleAsset: {localModuleIdentifier: "local-module:canvasComponent/FN86_ZFDn:default", url: "assets/Qpu32ehBfPhDTk2HGVecijvG3s.woff"}, url: new URL("assets/Qpu32ehBfPhDTk2HGVecijvG3s.woff", import.meta.url).href}, ...TypeformModalContentFonts])